import type { ReactElement } from 'react';
import { useEffect } from 'react';
import { navigate, useLocation } from '@gatsbyjs/reach-router';
import { graphql } from 'gatsby';
import type { PageProps } from 'gatsby';
import type { TeamPageQuery } from '../../generated/types';
import { navigateToLocalizedPath } from '../components/i18n/utils';
import { HomepageTemplate } from '../components/templates';
import { TeamPageContent } from '../modules/team/TeamPageContent';

export type Arkonaut = {
  executive?: boolean | null | undefined;
  id?: string | null | undefined;
  teamBio?: string | null | undefined;
  teamName?: string | null | undefined;
  teamPhoto?: string | null | undefined;
  teamRole?: string | null | undefined;
};

function TeamPage({ data: { content } }: PageProps<TeamPageQuery>): ReactElement {
  const location = useLocation();

  useEffect(() => {
    navigateToLocalizedPath(location.pathname, navigate);
  }, [location.pathname]);

  return (
    <HomepageTemplate seo={content?.frontmatter?.seo}>
      <TeamPageContent content={content} />
    </HomepageTemplate>
  );
}

export const query = graphql`
  query TeamPage($locale: String!) {
    content: markdownRemark(frontmatter: { layout: { eq: "team" }, locale: { eq: $locale } }) {
      frontmatter {
        locale
        teamIntro
        seo {
          title
          description
          image
        }
        teamTitle
        teamStoryIntro
        teamStoryCopy1
        teamStoryCopy2
        teamExecutivesTitle
        teamArkonautsTitle
        careersIntro
        careersCopy
        careersButton
        instructions
        teamMembers {
          teamName
          teamPhoto
          teamRole
          teamBio
          executive
          id
        }
        info {
          infoTop
          infoBottom
          id
        }
        investorsTitle
        investors {
          id
          logo
          name
          href
        }
      }
    }
  }
`;

export default TeamPage;
